import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";

import { useAnalyticsRouteTracker } from "@planity/analytics";
import { DefaultSeo } from "@planity/seo";
import { theme } from "@planity/ui";

export default function MarketingApp({ Component, pageProps }: AppProps) {
  useAnalyticsRouteTracker();

  return (
    <>
      <DefaultSeo
        appEnv={String(process.env.NEXT_PUBLIC_APP_ENV)}
        cardImageUrl={`${
          process.env.NEXT_PUBLIC_MARKETING_BASE_URL || ""
        }/card.png`}
        defaultTitle="Planity Events"
      />
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </>
  );
}
