import * as Sentry from "@sentry/nextjs";

export interface SentrySetupOptions {
  dsn?: string;
  appEnv?: string;
}

export function setupSentryConfig({ dsn, appEnv }: SentrySetupOptions) {
  if (dsn) {
    Sentry.init({
      dsn,
      environment: appEnv || process.env.NODE_ENV,
      tracesSampleRate: 1.0,
    });
  }
}

export function setupSentryClientConfig(options: SentrySetupOptions) {
  return setupSentryConfig(options);
}

export function setupSentryServerConfig(options: SentrySetupOptions) {
  return setupSentryConfig(options);
}
