import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";
import { Lato, Noto_Sans_Display } from "next/font/google";

const heading = Lato({ weight: "400", subsets: ["latin"] });
const body = Noto_Sans_Display({ subsets: ["latin"] });

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: false,
};

/** Planity UI Chakra Theme */
export const theme = extendTheme(
  {
    config,
    colors: {
      brand: {
        primary: "#18191B",
        secondary: "#DBE7FF",
      },
    },
    fonts: {
      heading: heading.style.fontFamily,
      body: body.style.fontFamily,
    },
    styles: {
      global: {
        "html, body": {
          height: "100%",
        },
      },
    },
  },
  withProse()
);
