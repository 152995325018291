import { DefaultSeo as NextDefaultSeo } from "next-seo";

export interface DefaultSeoProps {
  appEnv: string;
  cardImageUrl: string;
  defaultTitle?: string;
}

/** Default config for Next SEO. */
export function DefaultSeo({
  appEnv,
  cardImageUrl,
  defaultTitle = "Planity",
}: DefaultSeoProps) {
  return (
    <NextDefaultSeo
      dangerouslySetAllPagesToNoIndex={appEnv !== "production"}
      dangerouslySetAllPagesToNoFollow={appEnv !== "production"}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      description="Explore NYC Events"
      openGraph={{
        images: [
          {
            url: cardImageUrl,
            width: 1200,
            height: 628,
            alt: "Planity: Explore NYC Events",
            type: "image/png",
          },
        ],
        site_name: "Planity",
      }}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "/favicon.ico",
          // Fix for Chrome where .ico is chosen over SVG.
          sizes: "32x32",
        },
        {
          rel: "icon",
          href: "/icon.svg",
          type: "image/svg+xml",
        },
        {
          rel: "apple-touch-icon",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "manifest",
          href: "/app.webmanifest",
        },
      ]}
    />
  );
}
