import NextNProgress from "nextjs-progressbar";
import { useColorModeValue, useToken } from "@chakra-ui/react";

export function PageProgressBar() {
  const nprogressColor = useToken(
    "colors",
    useColorModeValue("brand.primary", "brand.secondary")
  );
  return (
    <NextNProgress color={nprogressColor} options={{ showSpinner: false }} />
  );
}
