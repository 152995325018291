import { load, trackPageview } from "fathom-client";
import { useRouter } from "next/router";
import * as React from "react";

import { FATHOM_SITE_ID, FATHOM_SCRIPT_URL, ALLOWED_DOMAINS } from "./config";

/** Fathom integration with Next.js Router. */
export function useAnalyticsRouteTracker() {
  const router = useRouter();

  React.useEffect(() => {
    load(FATHOM_SITE_ID, {
      url: FATHOM_SCRIPT_URL,
      includedDomains: ALLOWED_DOMAINS,
    });

    const onRouteChangeComplete = () => trackPageview();

    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);
}
