import * as React from "react";
import { useColorModeValue, useToken } from "@chakra-ui/react";

/** @deprecated */
export interface TextLogoProps {
  size?: number;
}

/** @deprecated */
export function TextLogo({ size = 384.3 }: TextLogoProps) {
  const colorToken = useColorModeValue("brand.primary", "brand.secondary");
  const color = useToken("colors", colorToken);

  return (
    <svg
      width={size}
      height={size * 0.1695799635}
      viewBox="0 0 384.3 65"
      style={{ maxWidth: "100%", height: "auto" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M187.8 0 230 55.5l-2.2.7V0h8v65h-8L185.6 9.6l2.2-.7V65h-8V0h8Zm-161 42H6.4v-7.5h20.4a15.637 15.637 0 0 0 4.504-.619 12.299 12.299 0 0 0 5.246-3.131 12.627 12.627 0 0 0 3.618-7.579A17.353 17.353 0 0 0 40.3 21a16.55 16.55 0 0 0-.522-4.271 11.906 11.906 0 0 0-3.228-5.579 12.691 12.691 0 0 0-7.233-3.475A17.539 17.539 0 0 0 26.8 7.5H8V65H0V0h26.8q4.5 0 8.4 1.4 3.9 1.4 6.85 4.1a18.615 18.615 0 0 1 4.496 6.358 21.4 21.4 0 0 1 .104.242 20.593 20.593 0 0 1 1.408 5.233A26.829 26.829 0 0 1 48.3 21q0 5-1.65 8.9a18.907 18.907 0 0 1-3.71 5.727 18.023 18.023 0 0 1-.89.873q-2.95 2.7-6.85 4.1a24.172 24.172 0 0 1-7 1.366 28.058 28.058 0 0 1-1.4.034Zm93.4 23h-8.4l24.7-65h8.4l24.9 65h-8.6L139.5 6.2h2.4L120.2 65ZM375.3 0h9l-23 42.7V65h-8V42.7L330.3 0h9l19.8 37.5h-3.2L375.3 0ZM60.8 0h8v57.5h36V65h-44V0Zm193 65V0h8v65h-8Zm41.5 0V6.7h8V65h-8Zm-22-57.5V0h52v7.5h-52ZM156.2 48H125v-7.5h31.2V48Z"
        fill={color}
      />
    </svg>
  );
}
